import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import ImageHero from "../components/image-hero"
import Articles from '../components/articles';
import Solutions from '../components/solutions';
import CTAImage from '../components/cta-image';

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {

  render() {
    // const page = this.props.data.wpPage;
    const page = this.props.data.wpPage;
    const { seo } = page
    const { title, featuredImage, excerpt, heroHeadline, heroCopy, left2Column, right2Column } = page.template.templateSolutions;

    const allSolutions = this.props.data.allWpSolution.nodes;
    const allPost = this.props.data.allWpPost.nodes;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        <ImageHero image={page.featuredImage} title={heroHeadline} copy={heroCopy} />
        <Solutions title="Our Global<br>Logistic Solutions" solutions={allSolutions} hideCTA={true} />
        <CTAImage leftColumn={left2Column} rightColumn={right2Column} />
        <Articles title="Our Latest Articles" posts={allPost} />

      </Layout>
    )
  }
}
export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      uri
      seo {
        ...seoParts
      }
      featuredImage {
        node {
          altText
          localFile {
            publicURL
          }
        }
      }
      template {
        ... on WpTemplate_OurSolutions {
          templateName
          templateSolutions {
            heroCopy
            heroHeadline
            left2Column {
              copy
              cta {
                target
                url
                title
              }
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
            right2Column {
              copy
              cta {
                target
                url
                title
              }
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    allWpSolution(filter: {parentId: {eq: null}}, sort: {fields: title, order: ASC}) {
      nodes {
        id
        title
        link
        cptSolution {
          shortDescription
          icon {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpPost(sort: {fields: date, order: DESC}, limit: 5) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }

`
import React from "react"
import { graphql, Link } from "gatsby"

import '../css/solutions.css';

import Icon from '../images/business-icon-min.png';
import Image from '../images/globe-background-min.png';

const Solutions = ({ title, solutions, hideCTA = false }) => { 
  var divStyle = {
    backgroundImage: 'url(' + Image + ')'
  }

  if(!title)
    title = 'Our Global<br />Logistic Solutions';
  
  return (
    <div className="container solutionsContainer" style={divStyle}>
      <div className="grid smallGrid align-center">
        <div className="col-sm-8 maincopy">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <hr />
        </div>
        { !hideCTA &&
          <div className="col-sm-4">
            <a className="mainCTA" href="/solutions">View All Solutions</a>
          </div>
        }
      </div>


      <div className="grid smallGrid">
        { solutions &&
          solutions.map(solution => {
            const { cptSolution } = solution;

            return (
              <div key={solution.id} className="col-sm-3 iconColumn maincopy">
                { cptSolution.icon && 
                  <Link to={solution.link}><img src={cptSolution.icon.localFile.publicURL} className="flex-img" alt={cptSolution.icon.altText} /></Link>
                }
                <Link className="iconTitle" to={solution.link}>{solution.title}</Link>
                <p>{cptSolution.shortDescription}</p>
              </div>
            )
          })
        }
      </div>

    </div>
  )
}

export default Solutions